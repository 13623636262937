import React from 'react'

export default class PasteVideo extends React.PureComponent {
  componentDidMount() {
    const Plyr = require('plyr')
    const controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
    this.player = new Plyr(this.containerEl, {
      autopause: true,
      vimeo: {
        controls: false,
        autoplay: false,
        h: this.props.content.videoHash
      },
      quality: { default: 576 },
      controls: controls
    })
  }

  componentWillUnmount() {
    this.player && this.player.destroy()
  }

  render() {
    return (
      <div className=''>
        <div
          ref={el => this.containerEl = el}
          data-plyr-provider="vimeo"
          data-plyr-embed-id={this.props.videoId}
          preload="true"
        />
      </div>
    )
  }
}
