import { useStaticQuery, graphql } from 'gatsby'

export const QueryPastes = () => {
  const query = useStaticQuery(
    graphql`
      {
        allSanityPastes {
          edges {
            node {
              id
              internal {
                type
                content
                contentDigest
                owner
              }
            }
          }
        }
      }
    `
  )
  const allPastes = []
  query.allSanityPastes.edges.forEach(edge => {
    let singleItem = {
      id: edge.node.id,
      data: JSON.parse(edge.node.internal.content)
    }
    allPastes.push(singleItem)
  })
  function chunkArray(array, size) {
    let result = []
    let arrayCopy = [...array]
    while (arrayCopy.length > 0) {
      result.push(arrayCopy.splice(0, size))
    }
    return result
  }
  return chunkArray(allPastes, 20)
}
