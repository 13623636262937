import React from 'react'
import cx from 'classnames'

// import META from '@/meta.js'
import store from '../state/store.js'
import Footer from '@/footer.js'
import { QueryPastes } from '../utls/queryPastes.js'
import MasonPastes from '@/modules/masonPastes.js'
import { Projects } from '@/svgs.js'

const updateStates = () => {
  store.hydrate({
    projectNav: !store.state.projectNav
  })()
  const html = document.querySelector('html')
  html.classList.add('opened')
}

const Pasteboard = ({ transitionStatus, entry, exit }) => {
  const data = QueryPastes()
  return (
    <div className={cx('animate__page', transitionStatus)}>
      <div className='nav__push pb' />
      <div className='container--xl ma x p1 projectNavContain pasteboardSpacer'>
        <div className='f jcs aic work__landing-nav' onClick={updateStates}>
          <Projects /><h4 className='p0 m0 mono h5 caps'>Projects</h4>
        </div>
      </div>
      <div className='container--xl mxa p1 simple-xl'>
        <h1 className='mt0 pt0'>Pasteboard</h1>
        <MasonPastes pastes={data} />
      </div>
      <Footer />
    </div>
  )
}

export default Pasteboard
