import React from 'react'
import Image from '@/image.js'
import Helmet from 'react-helmet'

import Slideshow from '@/modules/slideshow.js'
import PasteVideo from '@/modules/pasteVideo.js'

import { Projects } from '@/svgs.js'

class MasonPastes extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activePosts: this.props.pastes[0],
      nextPage: this.props.pastes.length > 0 ? 1 : null,
      totalPages: this.props.pastes.length
    }
    this.pastes = React.createRef()
  }
  componentDidMount () {
    const Macy = require('macy')
    const pasteBoard = Macy({
      container: this.pastes.current,
      trueOrder: false,
      waitForImages: false,
      margin: 14,
      columns: 3,
      breakAt: {
        1200: 3,
        900: 2,
        600: 1
      }
    })
    this.setState({
      pasteBoard: pasteBoard
    })
    setTimeout(() => {
      pasteBoard.reInit()
    })

    setTimeout(() => {
      pasteBoard.reInit()
    }, 300)
  }
  returnType (data) {
    if (data.slideshowImages && data.slideshowImages.length > 1) {
      return (<Slideshow images={data.slideshowImages} />)
    }
    if (data.videoId) {
      return (<PasteVideo videoId={data.videoId} />)
    }
    return (<Image width={900} imageId={data.imageId} source={data.image} />)
  }
  loadMore () {
    this.setState(prevState => ({
      activePosts: prevState.activePosts.concat(this.props.pastes[prevState.nextPage]),
      nextPage: prevState.nextPage + 1
    }))
    setTimeout(() => {
      this.state.pasteBoard.reInit()
    },500)

  }
  render () {
    const {
      pastes
    } = this.props
    return (
      <div className='x pasteImages'>
        <div ref={this.pastes}>
          <Helmet>
            <link rel="stylesheet" href="/plyr/plyr.css" />
          </Helmet>
          {this.state.activePosts.map(paste => (
            <div className='imageHolder' key={paste.id}>
              {this.returnType(paste.data)}
              <h4 className='py05 m0'>{paste.data.title}</h4>
            </div>
          ))}
        </div>
        {this.state.nextPage !== this.state.totalPages && (
          <h5 className='mono cursor caps loadMore'  onClick={() => this.loadMore()}>Load more <Projects className="paste__more" /></h5>
        )}
      </div>
    )
  }
}

export default MasonPastes
